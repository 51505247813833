import React, { useEffect, useRef, useState, KeyboardEvent } from 'react';
import { Layout as MainLayout } from '../components/Layout';
import {
  AllReactAttributes,
  ReactEventContent,
  ReactEventTypeContent,
} from '../templates/ReactEventTypeSearch/ReactEventConstants';
import { ReactEventType } from '../templates/ReactEventTypeSearch/ReactEventType';
import styles from './ReactEventTypeSearch.module.scss';
import { CircleAnimation } from '../components/CircleAnimation';
import { Helmet } from 'react-helmet';

const initialType = 'onLoad';
const ReactEventTypeSearch = () => {
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);
  const [currentType, setCurrentType] = useState(initialType);
  const findAttribute = (content: ReactEventContent) =>
    content?.attributes?.find((attr) => attr.toLowerCase() === currentType.toLowerCase());
  const content = ReactEventTypeContent.find((content) => findAttribute(content));
  const attribute = findAttribute(content);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 || e.keyCode === 27) {
      (e.target as HTMLElement)?.blur?.();
    }
  };
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <MainLayout title="React Event Type Search">
      <div className="article py-32 max-w-8xl">
        <Helmet title="Search all React event TypeScript interfaces"></Helmet>
        <div className={styles.wrapper}>
          <h1 style={{ textAlign: 'center' }}>
            Find the right TypeScript interface for <span className={styles.highlight}>any</span>{' '}
            React event{' '}
          </h1>
          <input
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={handleKeyPress}
            value={attribute}
            onChange={(e) => setCurrentType(e.target.value)}
            list="react-event-types-list"
            ref={inputRef}
            className="text-base p-8"
          >
            <datalist id="react-event-types-list">
              {AllReactAttributes.map((attr) => (
                <option value={attr}></option>
              ))}
            </datalist>
          </input>
          {!content && focused ? (
            <div style={{ margin: '7rem auto' }}>
              <CircleAnimation />
            </div>
          ) : (
            <ReactEventType content={content} attribute={attribute} />
          )}
        </div>
      </div>
    </MainLayout>
  );
};

export default ReactEventTypeSearch;
